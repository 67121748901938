import type { DrawingState, Line } from "@repo/types"
import { create } from "zustand"

export const useDrawingStore = create<DrawingState>((set) => ({
  mode: "feather",
  lines: [],
  currentLine: [],
  brushThickness: 2,

  startLine: (point) => set({ currentLine: [point] }),

  addPoint: (point) =>
    set((state) => ({
      currentLine: [...state.currentLine, point]
    })),

  completeLine: () =>
    set((state) => {
      if (state.currentLine.length < 2) return state

      const newLine: Line = {
        mode: state.mode,
        points: state.currentLine,
        thickness: state.brushThickness
      }

      return {
        lines: [...state.lines, newLine],
        currentLine: []
      }
    }),

  undo: () =>
    set((state) => ({
      lines: state.lines.slice(0, -1)
    })),

  removeLines: () => set({ lines: [] }),

  setBrushThickness: (brushThickness) => set({ brushThickness }),
  setMode: (mode) => set({ mode })
}))
