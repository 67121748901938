import type { GlobalComponent } from "@repo/types"
import cx from "classnames"

import styles from "./Slider.module.css"

export function Slider({
  value,
  onValueChange,
  layoutClassName
}: GlobalComponent) {
  const max = 50

  return (
    <div className={cx(styles.box, layoutClassName)}>
      <div className={styles.wrap} style={{ "--max": max }}>
        <input
          type='range'
          min={1}
          max={max}
          className={styles.slider}
          value={value}
          onChange={handleSliderChange}
          style={{ "--value": 1 + value / max }}
        />

        <svg
          className={styles.outline}
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 50 110'
        >
          <title>SVG</title>
          <defs>
            <linearGradient id='MyGradient' gradientTransform='rotate(90)'>
              <stop offset='5%' stop-color='#8f9baa' />
              <stop offset='95%' stop-color='#d1d7dc' />
            </linearGradient>

            <linearGradient id='MyBorder' gradientTransform='rotate(90)'>
              <stop offset='0' stop-color='var(--border, #5f6074)' />
              <stop offset='1' stop-color='var(--border, #c3ccd6)' />
            </linearGradient>

            <filter id='inset-shadow'>
              <feColorMatrix
                in='SourceGraphic'
                type='matrix'
                values='
                  0 0 0 0 0 
                  0 0 0 0 0 
                  0 0 0 0 0 
                  0 0 0 100 0'
                result='opaque-source'
              />
              <feGaussianBlur stdDeviation='5' />
              <feOffset dy='5' />
              <feComposite operator='xor' in2='opaque-source' />
              <feComposite operator='in' in2='opaque-source' />
              <feComposite operator='over' in2='SourceGraphic' />
            </filter>
          </defs>

          <path
            strokeWidth={1}
            stroke='url(#MyBorder)'
            d='m49.1761 29.6495-.0951.5808h.0024L37.9457 96.8855h-.0043l-.0709.416c-1.0496 6.1595-6.4135 10.8475-12.8716 10.8475-6.4931 0-11.8802-4.74-12.8883-10.9481l-.0004-.0022L.821738 29.6495c-.000042-.0002-.000084-.0005-.000126-.0007-.212268-1.2973-.322695-2.629-.322695-3.987 0-13.5309 10.968983-24.49997 24.499983-24.49997s24.5 10.96907 24.5 24.49997c0 1.3582-.1105 2.6903-.3228 3.9877Z'
          />
          <path
            fill='url(#MyGradient)'
            filter='url(#inset-shadow)'
            d='m49.1761 29.6495-.0951.5808h.0024L37.9457 96.8855h-.0043l-.0709.416c-1.0496 6.1595-6.4135 10.8475-12.8716 10.8475-6.4931 0-11.8802-4.74-12.8883-10.9481l-.0004-.0022L.821738 29.6495c-.000042-.0002-.000084-.0005-.000126-.0007-.212268-1.2973-.322695-2.629-.322695-3.987 0-13.5309 10.968983-24.49997 24.499983-24.49997s24.5 10.96907 24.5 24.49997c0 1.3582-.1105 2.6903-.3228 3.9877Z'
            shapeRendering='crispEdges'
          />
        </svg>
      </div>
    </div>
  )

  function handleSliderChange(e) {
    onValueChange(Number(e.currentTarget.value))
  }
}
