import { useSize } from "@state/useSize"
import { type MutableRefObject, useEffect } from "react"

function useElementSize(
  ref: MutableRefObject<HTMLElement | null>, 
  onSizeChange: ({ width, height }: { width: number, height: number }) => void
) {
  const { size } = useSize()

  useEffect(() => {
    if (!ref.current) return

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect
        if (width && width !== size.width) onSizeChange({ width, height })
      }
    })

    observer.observe(ref.current)

    const { width, height } = ref.current.getBoundingClientRect()
    if (width && width !== size.width) onSizeChange({ width, height })

    return () => {
      observer.disconnect()
    }
  }, [ref, size, onSizeChange])
}

export default useElementSize
