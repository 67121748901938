import { useSize } from "@state/useSize"
import { useEffect, useState } from "react"
import { optimize } from "svgo"

export function useCanvasToPng(
  canvas: HTMLCanvasElement | null,
  svg: { svg: SVGSVGElement } | null
) {
  const [base64, setBase64] = useState<string | null>(null)
  const [pngUrl, setPngUrl] = useState<string | null>(null)

  const size = useSize(x => x.size)

  useEffect(() => {
    if (!svg?.svg) return

    const svgData = new XMLSerializer().serializeToString(svg.svg)
    const maskColoredSvgData = updateSvgColors(svgData)
    const optimized = optimize(maskColoredSvgData)

    setDimensions(canvas)

    useLoadImage({
      src: `data:image/svg+xml;base64,${btoa(optimized.data)}`,
      onLoad: (x) => setPngUrl(x),
    })

    setBase64(`data:image/svg+xml;base64,${btoa(optimized.data)}`)
  }, [canvas, svg])

  return { pngUrl, base64 }

  function setDimensions(canvas: HTMLCanvasElement | null) {
    if (!svg) return
    // const svgWidth = svg.svg.clientWidth
    // const svgHeight = svg.svg.clientHeight

    if (!canvas) return
    // canvas.width = svgWidth / 10
    // canvas.height = svgHeight / 10
    canvas.width = size.width
    canvas.height = size.height
  }

  function useLoadImage({
    src,
    onLoad
  }: { src: string; onLoad: (x: string) => void }) {
    const ctx = canvas?.getContext("2d")
    if (!ctx) return

    const img = new Image()

    img.onload = () => {
      ctx.drawImage(img, 0, 0)
      const pngBase64 = canvas?.toDataURL("image/png", 1)
      if (pngBase64) onLoad(pngBase64)
    }

    img.src = src
  }
}

function updateSvgColors(x: string): string {
  return x
    .replace(/fill="transparent"/g, 'fill="black"')
    .replace(/fill="(?!black")[^"]*"/g, 'fill="white"') 
    .replace(/stroke="[^"]*"/g, 'stroke="white"')
}
