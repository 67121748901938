import { Slider } from "@components/Slider"
import {
  FloatingPortal,
  autoUpdate,
  flip,
  size,
  useFloating
} from "@floating-ui/react"
import type {
  GlobalComponent,
  ToolbarButtonProps,
  ToolbarProps
} from "@repo/types"
import { useDrawingStore } from "@state/useDrawStore"
import { usePromptStore } from "@state/usePromptStore"
import { Link } from "@tanstack/react-router"

import cx from "classnames"

import styles from "./Toolbar.module.css"

import iconArrowUp from "@icons/arrow-up.svg?raw"
import iconBin from "@icons/bin.svg?raw"
import iconBolt from "@icons/bolt.svg?raw"
import iconBrush from "@icons/brush.svg?raw"
import iconBucket from "@icons/bucket.svg?raw"
import iconChevronDown from "@icons/chevron-down.svg?raw"
import iconGallery from "@icons/gallery.svg?raw"
import iconLasso from "@icons/lasso.svg?raw"
import iconShare from "@icons/share.svg?raw"
import iconUndo from "@icons/undo.svg?raw"
import { type ForwardedRef, forwardRef, useState } from "react"

export function Toolbar({
  busy,
  onUndo,
  onSend,
  onMode,
  layoutClassName = undefined
}: ToolbarProps) {
  const brushThickness = useDrawingStore((x) => x.brushThickness)
  const { setBrushThickness } = useDrawingStore()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <StyleSelector layoutClassName={styles.styleSelectorLayout} />
      <PrimaryActions {...{ busy, onMode }} />

      <Card layoutClassName={styles.sliderLayout}>
        <Slider value={brushThickness} onValueChange={setBrushThickness} />
      </Card>

      <Promptbox
        disabled={busy}
        onClick={onSend}
        layoutClassName={styles.promptboxLayout}
      />

      <SecondaryActions {...{ busy, onUndo, onMode }} />
    </div>
  )
}

const temporaryStyleMap = {
  modern: {
    title: "Modern",
    image:
      "https://images.unsplash.com/photo-1595658511703-2cad160de181?q=80&w=1738&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  scandi: {
    title: "Scandi",
    image:
      "https://plus.unsplash.com/premium_photo-1684779978703-37726152ee9a?q=80&w=1847&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  }
}

function StyleSelector({ layoutClassName }: GlobalComponent) {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    onOpenChange: setIsOpen,
    open: isOpen,
    middleware: [
      flip(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${Math.max(0, rects.reference.width)}px`
          })
        }
      })
    ]
  })

  return (
    <>
      <Card ref={refs.setReference} {...{ layoutClassName }}>
        <div className={styles.componentStyleSelector}>
          <StyleButton
            onClick={() => setIsOpen(!isOpen)}
            title={temporaryStyleMap.scandi.title}
            image={temporaryStyleMap.scandi.image}
          />
        </div>
      </Card>

      {isOpen && (
        <FloatingPortal>
          <div ref={refs.setFloating} style={floatingStyles}>
            <ul className={styles.portalListView}>
              {Object.entries(temporaryStyleMap).map(
                ([k, { image, title }]) => (
                  <StyleButton key={k} onClick={null} {...{ image, title }} />
                )
              )}
            </ul>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

function StyleButton({ title, image, onClick }) {
  return (
    <button
      type='button'
      className={styles.imageSelector}
      style={{ "--background-image": `url(${image})` }}
      {...{ onClick }}
    >
      <span className={styles.imageSelectorInner}>
        <p className={styles.styleName}>{title}</p>
        <ChevronDownIcon layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}

function Actions({ children, withBackgroundElement = false }) {
  const mode = useDrawingStore((x) => x.mode)

  return (
    <Card>
      <div
        className={styles.componentActions}
        data-with-background-element={withBackgroundElement}
        data-active={mode}
      >
        {children}
      </div>
    </Card>
  )
}

function PrimaryActions({
  busy,
  onMode
}: Pick<ToolbarProps, "busy" | "onMode">) {
  const mode = useDrawingStore((x) => x.mode)

  return (
    <Actions withBackgroundElement>
      <ToolbarButton
        isActive={mode === "feather"}
        onClick={() => onMode("feather")}
        disabled={busy}
      >
        <BrushIcon />
      </ToolbarButton>

      <ToolbarButton
        isActive={mode === "lasso"}
        onClick={() => onMode("lasso")}
        disabled={busy}
      >
        <LassoIcon />
      </ToolbarButton>

      <ToolbarButton
        isActive={mode === "one-shot"}
        onClick={() => onMode("one-shot")}
        disabled={busy}
      >
        <BucketIcon />
      </ToolbarButton>
    </Actions>
  )
}

function SecondaryActions({
  busy,
  onUndo
}: Pick<ToolbarProps, "busy" | "onUndo">) {
  return (
    <Actions>
      <ToolbarButton onClick={onUndo} disabled={busy}>
        <BinIcon />
      </ToolbarButton>

      <ToolbarLink>
        <GalleryIcon />
      </ToolbarLink>

      <ToolbarButton disabled={busy}>
        <ShareIcon />
      </ToolbarButton>
    </Actions>
  )
}

function Promptbox({ disabled, onClick, layoutClassName }: GlobalComponent) {
  const setPrompt = usePromptStore((x) => x.set)
  const prompt = usePromptStore((x) => x.prompt)

  return (
    <Card {...{ layoutClassName }}>
      <div className={styles.componentPromptbox}>
        <textarea
          value={prompt}
          className={styles.textarea}
          onChange={(e) => setPrompt(e.target.value)}
          onKeyDown={handleKeyDown}
          {...{ disabled }}
        />
        <button
          type='submit'
          className={styles.button}
          {...{ disabled, onClick }}
        >
          <SendButton />
        </button>
      </div>
    </Card>
  )

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter") onClick()
  }
}

function ToolbarButton({
  children,
  onClick,
  isActive = false,
  disabled = false,
  layoutClassName = undefined
}: ToolbarButtonProps) {
  return (
    <button
      type='button'
      className={cx(
        styles.componentButton,
        isActive && styles.isActive,
        layoutClassName
      )}
      {...{ disabled, onClick }}
    >
      {children}
    </button>
  )
}

// TODO: Refactor / variants
function ToolbarLink({
  children,
  layoutClassName = undefined
}: GlobalComponent) {
  return (
    <Link to='/gallery' className={cx(styles.componentButton, layoutClassName)}>
      {children}
    </Link>
  )
}

function BoltIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconBolt }}
    />
  )
}

function UndoIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconUndo }}
    />
  )
}

function ChevronDownIcon({ layoutClassName }: GlobalComponent) {
  return (
    <span
      className={cx(styles.icon, layoutClassName)}
      dangerouslySetInnerHTML={{ __html: iconChevronDown }}
    />
  )
}

function BrushIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconBrush }}
    />
  )
}

function SendButton() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconArrowUp }}
    />
  )
}

function GalleryIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconGallery }}
    />
  )
}

function LassoIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconLasso }}
    />
  )
}

function BucketIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconBucket }}
    />
  )
}

function ShareIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconShare }}
    />
  )
}

function BinIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconBin }}
    />
  )
}

function Loading() {
  return <span className={styles.componentLoader} />
}

const Card = forwardRef(CardImpl)
function CardImpl(
  { children, layoutClassName = undefined }: GlobalComponent,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div className={cx(styles.componentCardImpl, layoutClassName)} {...{ ref }}>
      {children}
    </div>
  )
}
