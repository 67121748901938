import { type APIError, useApiMutation, useApiQuery } from "@api/fetch"
import { useAuth, useUser } from "@clerk/clerk-react"
import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton
} from "@clerk/clerk-react"
import { useCanvasToPng } from "@machinery/useCanvasToPng"
import type {
  CreditsResponse,
  GlobalComponent,
  ImageNewResponse,
  ImageUploadResponse,
  SelectTransaction,
  SelectedFile,
  WithCredits
} from "@repo/types"
import { Route } from "@routes/_auth.editor.$id"
import { useCredits } from "@state/useCredits"
import { useLabel } from "@state/useLabel"
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import { useEffect, useRef } from "react"

import projectQueries from "@api/queries/projects"
import cx from "classnames"

import { PaintModule2 } from "./PaintModule2"
import { Toolbar } from "./Toolbar"
import { Uploader } from "./Uploader"

import { useDrawingStore } from "@state/useDrawStore"
import { useFile } from "@state/useFile"
import { usePromptStore } from "@state/usePromptStore"
import { useSize } from "@state/useSize"

import logo from "@assets/logo-icon.svg"

import styles from "./Workspace.module.css"

export function Workspace() {
  const { getToken } = useAuth()
  const { id } = Route.useParams()
  const { data: project } = useSuspenseQuery(
    projectQueries.detail(id, getToken)
  )

  const { undo, setMode, removeLines } = useDrawingStore()
  const lines = useDrawingStore((x) => x.lines)

  const { set: setFile } = useFile()

  const fileMutation = useApiMutation<ImageUploadResponse>("/images/upload", {
    onSuccess: console.debug,
    onError: console.error
  })

  const clearPrompt = usePromptStore((x) => x.clear)
  const prompt = usePromptStore((x) => x.prompt)

  const label = useLabel((x) => x.label)
  const base64 = useFile((x) => x.base64)
  const name = useFile((x) => x.name)

  const imageMutation = useApiMutation<ImageNewResponse & WithCredits>(
    "/falai",
    {
      onSuccess: handleSuccess,
      onError: handleError
    }
  )

  const paintModuleRef = useRef(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { pngUrl } = useCanvasToPng(canvasRef.current, paintModuleRef.current)

  const { user } = useUser()
  const { balance: currentBalance, set: setCredits } = useCredits()

  const { data } = useApiQuery<CreditsResponse>(
    ["credits", user?.id],
    "/credits/balance"
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentBalance !== data?.balance && data?.balance)
      setCredits(data.balance)
  }, [currentBalance, data])

  const file: SelectedFile = name
    ? { name, base64 }
    : { name: null, base64: null }

  return (
    <section className={styles.component}>
      <canvas ref={canvasRef} style={{ display: "none" }} />

      <Uploader
        onFile={handleFile}
        layoutClassName={styles.uploaderLayout}
        mutation={imageMutation}
        {...{ file }}
      />

      {/* TODO: i18n */}
      {imageMutation.isPending && <p className={styles.loading}>Processing</p>}

      <PaintModule2
        file={base64 || project?.image?.url || undefined}
        ref={paintModuleRef}
        busy={imageMutation.isPending}
        layoutClassName={styles.paintModuleLayout}
      />

      <Logo layoutClassName={styles.logoLayout} />

      <Toolbar
        busy={imageMutation.isPending}
        onUndo={undo}
        onMode={setMode}
        onSend={handleSubmit}
        layoutClassName={styles.toolbarLayout}
      />

      {/* {project.generations && (
        <History
          history={project.generations}
          layoutClassName={styles.historyLayout}
        />
      )} */}

      <Profile layoutClassName={styles.profileLayout} />
    </section>
  )

  function handleFile(file: SelectedFile) {
    if (!file) return
    fileMutation.mutate({ id, file: file.base64, fileName: file.name || null })
    setFile(file)
  }

  function handleSubmit() {
    imageMutation.mutate({
      id,
      label,
      prompt,
      fileName: name || null,
      mask: lines?.length ? pngUrl : null,
      file: base64 || project?.image?.url || null
    })
  }

  async function handleSuccess({
    url,
    balance
  }: ImageNewResponse & WithCredits) {
    // TODO: Also update history / generations
    setFile({ base64: url })
    setCredits(balance)
    clearPrompt()
    removeLines()
  }

  function handleError(e: APIError) {
    console.log("error", e)
  }
}

// function History({
//   history,
//   layoutClassName
// }: { history: Array<string> } & GlobalComponent) {
//   return (
//     <div className={cx(styles.componentHistory, layoutClassName)}>
//       {history?.map((x: string) => (
//         <img alt='' key={x} src={x} className={styles.image} />
//       ))}
//     </div>
//   )
// }

function Logo({ layoutClassName }: GlobalComponent) {
  return (
    <Link to='/new' className={cx(styles.componentLogo, layoutClassName)}>
      <img className={styles.logo} alt='' src={logo} />
    </Link>
  )
}

function Profile({ layoutClassName }: GlobalComponent) {
  return (
    <div className={cx(styles.profile, layoutClassName)}>
      <SignedOut>
        <SignInButton />
      </SignedOut>
      <SignedIn>
        {/**
            NOTE: Hier kan settings in.
            @see https://clerk.com/docs/customization/user-profile
          */}
        <UserButton>
          <UserButton.UserProfilePage
            label='Admin Settings'
            url='/admin'
            labelIcon={<div />}
          >
            <AdminSettings />
          </UserButton.UserProfilePage>

          <UserButton.UserProfilePage
            label='Transactions'
            url='/transactions'
            labelIcon={<div />}
          >
            <TransactionsView />
          </UserButton.UserProfilePage>
        </UserButton>
      </SignedIn>
    </div>
  )
}

function AdminSettings() {
  const { user } = useUser()
  const queryClient = useQueryClient()

  const { mutate: increaseMutation } = useApiMutation("/credits/increase", {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["credits", user?.id] })
  })

  const { mutate: decreaseMutation } = useApiMutation("/credits/decrease", {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["credits", user?.id] })
  })

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <button type='button' onClick={() => increaseMutation({})}>
        increase with 1 credit
      </button>

      <button type='button' onClick={() => decreaseMutation({})}>
        decrease with 1 credit
      </button>
    </div>
  )
}

function TransactionsView() {
  const { data } = useApiQuery<{ transactions: Array<SelectTransaction> }>(
    ["transactions"],
    "/transactions/list"
  )

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <table>
        <thead>
          <th>id</th>
          <th>type</th>
          <th>amount</th>
          <th>createdAt</th>
        </thead>

        <tbody>
          {data?.transactions?.map((x) => (
            <tr key={x.id}>
              <td>{x.id}</td>
              <td>{x.type}</td>
              <td>{x.amount}</td>
              <td>{new Date(x.createdAt).toLocaleDateString("nl")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
