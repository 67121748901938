import type { SizeState } from "@repo/types"
import { create } from "zustand"

export const useSize = create<SizeState>((set) => ({
  setSize: ({ width, height }) => set({ 
    rounded: { width: Math.round(width), height: Math.round(height) }, 
    size: { width, height }, 
  }),
  rounded: { width: 0, height: 0 },
  size: { width: 0, height: 0 },
}))
